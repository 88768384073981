import { consoleLog } from 'xpermeet-lib';
import { SOCKET_CONNECTION_STATUS } from '@/constants/enums';
import { getInstance } from '@/xpermeet';

const xperMeetLib = getInstance();

class streamMessage {
  constructor() {
    this.API_URL = process.env.VUE_APP_STREAM_MESSAGE_WS_URL;

    this.ws = null;
    this.keepAliveInterval = null;
    this.socketConnectionStatus = SOCKET_CONNECTION_STATUS.IDLE;
    this.pingSent = false;
    this.retryCount = 1;
  }

  connectWs() {
    if (this.ws) {
      consoleLog('Already have a WS connection!');
      return Promise.reject();
    }

    return new Promise((resolve) => {
      this.socketConnectionStatus = SOCKET_CONNECTION_STATUS.CONNECTING;
      try {
        setTimeout(() => {
          const goLiveToken = localStorage.getItem('go_live_token');
          this.ws = new WebSocket(`${this.API_URL}/chat?goLiveToken=${goLiveToken}`);

          this.ws.onmessage = this.wsOnMessage.bind(this);

          this.ws.onclose = () => {
            this.socketConnectionStatus = SOCKET_CONNECTION_STATUS.CLOSED;
            this.ws = null;
            this.closeWS('retry');
          };

          this.ws.onerror = () => {
            consoleLog('WS onerror');
            this.closeWS('retry');
          };

          this.ws.onopen = () => {
            this.socketConnectionStatus = SOCKET_CONNECTION_STATUS.CONNECTED;
            this.startPing();
            this.retryCount = 1;
            resolve();
          };

          window.onbeforeunload = () => {
            this.closeWS('onbeforeunload');
          };
        }, 1000);
      } catch (error) {
        consoleLog(error);
        resolve();
      }
    });
  }

  wsOnMessage(message) {
    const jsonMessage = JSON.parse(message.data);
    switch (jsonMessage.type) {
      case 'ERROR':
        this.handleError(jsonMessage);
        break;
      case 'PONG':
        this.pingSent = false;
        break;
      case 'CHAT_MESSAGE':
        xperMeetLib.conference.sendMessage(JSON.stringify(jsonMessage.payload.content));
        break;
      case 'VIEWER_COUNT':
        xperMeetLib.conference.viewerCountUpdateCommand(jsonMessage.payload.content);
        break;
      default:
        consoleLog('[onmessage] Received message: ' + message.data);
        break;
    }
  }

  closeWS(caller) {
    consoleLog('WS closing', caller);
    this.stopPing();
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
    if (caller === 'retry') {
      setTimeout(() => {
        this.retryCount++;
        this.stopPing();
        this.start();
      }, this.retryCount * 3000);
    }
  }

  startPing() {
    if (!this.ws || this.keepAliveInterval) {
      this.stopPing();
    }
    this.keepAliveInterval = setInterval(() => {
      if (!this.pingSent) {
        this.pingSent = true;
        this.wsSendMessage({ type: 'PING' });
      } else {
        this.closeWS('retry');
      }
    }, 60000);
  }

  wsSendMessage(message) {
    const jsonMessage = JSON.stringify(message);
    this.ws.send(jsonMessage);
  }

  stopPing() {
    clearInterval(this.keepAliveInterval);
  }

  handleError(jsonMessage) {
    consoleLog({ payload: jsonMessage.payload, jsonMessage });
    this.closeWS('retry'); //TODO
  }

  async start() {
    await this.connectWs();
  }
}
export default new streamMessage();

