<template>
  <div class="jibri-screen">
    <Broadcast jibri />
    <UserAudioContainer jibri />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import Broadcast from '@/components/Broadcast/Broadcast.vue';
import UserAudioContainer from '@/components/UserAudioContainer.vue';
import { STUDIO } from '@/constants/modules';
import streamMessage from '@/utils/streamMessage';
import { clearStudioName } from '@/helpers/studio';

export default {
  name: 'Jibri',
  components: { Broadcast, UserAudioContainer },
  computed: {
    ...mapState(STUDIO, ['conferenceJoined']),
    ...mapGetters(STUDIO, ['getStagedUserIds']),
    sessionId() {
      return clearStudioName(this.$route.params.sessionId);
    },
    config() {
      const { p, s } = this.$route.query;
      const password = p ? atob(p) : undefined;
      return { roomName: this.sessionId, password, settings: s, startMutedPolicyConfig: {} };
    },
  },
  created() {
    this.initXperMeet(this.config);
  },
  methods: {
    ...mapActions(STUDIO, ['initXperMeet', 'setReceiverConstraints']),
    selectParticipant() {
      const constraints = {
        selectedEndpoints: [],
        onStageEndpoints: [],
        defaultConstraints: { maxHeight: 480 },
        constraints: {},
      };

      this.getStagedUserIds.forEach((userId) => {
        constraints.selectedEndpoints.push(userId);
        constraints.onStageEndpoints.push(userId);
        constraints.constraints[userId] = { maxHeight: 1080 };
      });

      this.setReceiverConstraints(constraints);
    },
  },
  watch: {
    conferenceJoined() {
      if (this.conferenceJoined) {
        streamMessage.start();
      }
    },
    sessionId() {
      this.initXperMeet(this.config);
    },
    getStagedUserIds: 'selectParticipant',
  },
};
</script>

<style scoped lang="scss">
.jibri-screen {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>

