<template>
  <div class="destination-avatar">
    <img v-if="avatar" :src="avatar" class="avatar-image" :style="destinationAvatarStyle" referrerpolicy="no-referrer" />
    <div v-else class="static-avatar" :style="destinationAvatarStyle">
      <AppIcon icon="user" />
    </div>
    <div v-if="!hideIcon">
      <div v-if="Array.isArray(destinationTypes)" class="d-flex sender-selected-sessions" :class="{ multiple: destinationTypes.length > 1 }">
        <ProviderIcon
          v-for="(image, key) in destinationTypes"
          class="stream-medium-avatar"
          :class="{ 'ml-2': key !== 0 }"
          :provider="image"
          :key="key"
          :style="iconStyle"
        />
      </div>
      <ProviderIcon
        class="stream-image stream-medium-avatar"
        v-else-if="destinationTypes"
        :provider="destinationTypes"
        :class="iconPosition"
        :style="iconStyle"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'DestinationAvatar',
  props: {
    avatar: String,
    destinationTypes: [String, Array],
    iconPosition: {
      type: String,
      default: 'left',
    },
    size: {
      type: [Number, String],
      default: 36,
    },
    iconHeight: {
      type: [Number, String],
    },
    hideIcon: {
      type: Boolean,
    },
  },
  computed: {
    destinationAvatarStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
    iconStyle() {
      return {
        height: `${this.iconHeight ? this.iconHeight : ''}px`,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.destination-avatar {
  position: relative;
  .stream-medium-avatar {
    height: 20px;
  }
  .avatar-image {
    border-radius: 50%;
  }
  .static-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: var(--v-info-darken3);
  }
  .sender-selected-sessions {
    position: absolute;
    bottom: -5px;
    left: -5px;
    &.multiple {
      left: -4px;
    }
  }
  .stream-image {
    position: absolute;
    bottom: -5px;
    left: -5px;
    &.right {
      left: auto;
      right: -5px;
    }
  }
}
</style>

