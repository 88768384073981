var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "destination-avatar" }, [
    _vm.avatar
      ? _c("img", {
          staticClass: "avatar-image",
          style: _vm.destinationAvatarStyle,
          attrs: { src: _vm.avatar, referrerpolicy: "no-referrer" },
        })
      : _c(
          "div",
          { staticClass: "static-avatar", style: _vm.destinationAvatarStyle },
          [_c("AppIcon", { attrs: { icon: "user" } })],
          1
        ),
    !_vm.hideIcon
      ? _c(
          "div",
          [
            Array.isArray(_vm.destinationTypes)
              ? _c(
                  "div",
                  {
                    staticClass: "d-flex sender-selected-sessions",
                    class: { multiple: _vm.destinationTypes.length > 1 },
                  },
                  _vm._l(_vm.destinationTypes, function (image, key) {
                    return _c("ProviderIcon", {
                      key: key,
                      staticClass: "stream-medium-avatar",
                      class: { "ml-2": key !== 0 },
                      style: _vm.iconStyle,
                      attrs: { provider: image },
                    })
                  }),
                  1
                )
              : _vm.destinationTypes
              ? _c("ProviderIcon", {
                  staticClass: "stream-image stream-medium-avatar",
                  class: _vm.iconPosition,
                  style: _vm.iconStyle,
                  attrs: { provider: _vm.destinationTypes },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }